import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from "./AuthContext";
import { CreditCard, Loader2, AlertCircle, Zap, CheckCircle, XCircle, Calendar, RefreshCw, MessageCircle } from 'lucide-react';
import { Button } from "./components/ui/Button";
import { Card, CardHeader, CardContent } from "./components/ui/Card";
import { Alert } from "./components/ui/Alert";

const SubscriptionManagementPage = ({ darkMode }) => {
  const { user } = useAuth();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchSubscriptionData = useCallback(async () => {
    if (!user) return;

    try {
      const token = await user.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/subscription-status`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch subscription data');
      }

      const data = await response.json();
      setSubscriptionData(data);
    } catch (err) {
      console.error('Error fetching subscription data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchSubscriptionData();
  }, [fetchSubscriptionData]);

  const handleSubscriptionAction = async (action) => {
    setIsProcessing(true);
    try {
      const token = await user.getIdToken();
      const endpoint = action === 'upgrade' ? 'create-checkout-session' : 
                       action === 'cancel' ? 'cancel-subscription' : 'uncancel-subscription';
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} subscription`);
      }

      if (action === 'upgrade') {
        const { url } = await response.json();
        window.location = url;
      } else {
        await fetchSubscriptionData();
      }
    } catch (err) {
      console.error(`Error ${action}ing subscription:`, err);
      setError(`Failed to ${action} subscription. Please try again.`);
    } finally {
      setIsProcessing(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return null;
    
    if (timestamp._seconds !== undefined && timestamp._nanoseconds !== undefined) {
      return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    }
    
    if (typeof timestamp === 'number') {
      return new Date(timestamp * 1000);
    }
    
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  };

  const formatDateString = (date) => {
    if (!date) return 'N/A';
    return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  };

  if (isLoading) {
    return (
      <div className={`flex justify-center items-center h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`flex justify-center items-center h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <Alert variant="error" className="max-w-md">
          <XCircle className="h-4 w-4 mr-2" />
          <span>Error: {error}</span>
        </Alert>
      </div>
    );
  }

  const subscribedDate = formatDate(subscriptionData?.subscribedAt);
  const cancelDate = formatDate(subscriptionData?.cancelAtPeriodEnd);
  const isGracePeriod = !subscriptionData?.isSubscribed && cancelDate && cancelDate > new Date();

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-center">
          <CreditCard className="inline-block w-10 h-10 mr-3 text-terracotta dark:text-amber" />
          Subscription Management
        </h1>
        <Card className={`max-w-2xl mx-auto ${darkMode ? 'bg-warm_gray' : 'bg-cream'} shadow-lg`}>
          <CardHeader>
            <h2 className="text-2xl font-semibold flex items-center justify-center">
              {subscriptionData?.isSubscribed ? (
                <CheckCircle className="w-8 h-8 mr-3 text-terracotta dark:text-amber" />
              ) : isGracePeriod ? (
                <AlertCircle className="w-6 h-6 mr-2 text-amber dark:text-terracotta" />
              ) : (
                <XCircle className="w-8 h-8 mr-3 text-terracotta dark:text-amber" />
              )}
              {subscriptionData?.isSubscribed ? 'Sage Evergreen' : (isGracePeriod ? 'Subscription Ending Soon' : 'Sage Sapling')}
            </h2>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className={`p-4 rounded-lg ${darkMode ? 'bg-warm_gray' : 'bg-cream'}`}>
              <p className="font-medium mb-2">Subscription Details:</p>
              <p className={`${darkMode ? 'text-white' : 'text-warm_gray'} flex items-center`}>
                <MessageCircle className="w-4 h-4 mr-2" />
                {subscriptionData?.isSubscribed ? 'Unlimited conversations' : 'One conversation per week'}
              </p>
              {subscribedDate && (
                <p className={`${darkMode ? 'text-white' : 'text-warm_gray'} flex items-center mt-2`}>
                  <Calendar className="w-4 h-4 mr-2" />
                  Active since: {formatDateString(subscribedDate)}
                </p>
              )}
              {cancelDate && (
                <p className={`${darkMode ? 'text-white' : 'text-warm_gray'} flex items-center mt-2`}>
                  <Calendar className="w-4 h-4 mr-2" />
                  Access until: {formatDateString(cancelDate)}
                </p>
              )}
            </div>
            {isGracePeriod ? (
              <div className="space-y-4">
                <Alert variant="warning" className="shadow-md">
                  <div className="flex items-center">
                    <AlertCircle className="h-5 w-5 mr-2" />
                    <span>Your subscription has been cancelled. You still have access until the end of your billing period.</span>
                  </div>
                </Alert>
                <Button
                  onClick={() => handleSubscriptionAction('uncancel')}
                  disabled={isProcessing}
                  className={`w-full bg-amber hover:bg-terracotta text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center shadow-md`}
                >
                  {isProcessing ? (
                    <>
                      <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    <>
                      <RefreshCw className="w-5 h-5 mr-2" />
                      Resume Sage Evergreen
                    </>
                  )}
                </Button>
              </div>
            ) : subscriptionData?.isSubscribed ? (
              <Button
                onClick={() => handleSubscriptionAction('cancel')}
                disabled={isProcessing}
                className={`w-full bg-terracotta hover:bg-amber text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center shadow-md`}
              >
                {isProcessing ? (
                  <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    <XCircle className="w-5 h-5 mr-2" />
                    Cancel Sage Evergreen
                  </>
                )}
              </Button>
            ) : (
              <div className="space-y-6">
                <div className={`p-6 rounded-lg ${darkMode ? 'bg-warm_gray' : 'bg-cream'}`}>
                  <h3 className="text-xl font-semibold mb-4 flex items-center justify-center">
                    <Zap className="w-6 h-6 mr-2 text-amber dark:text-terracotta" />
                    Upgrade to Sage Evergreen
                  </h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <CheckCircle className="w-5 h-5 mr-2 text-terracotta dark:text-amber" />
                      <span>Unlimited conversations with Sage</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="w-5 h-5 mr-2 text-terracotta dark:text-amber" />
                      <span>Accelerated personal growth</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="w-5 h-5 mr-2 text-terracotta dark:text-amber" />
                      <span>24/7 access to AI guidance</span>
                    </li>
                  </ul>
                </div>
                <Button
                  onClick={() => handleSubscriptionAction('upgrade')}
                  disabled={isProcessing}
                  className={`w-full bg-amber hover:bg-terracotta text-white rounded-lg p-4 text-lg transition-colors duration-300 ease-in-out flex items-center justify-center shadow-md`}
                >
                  {isProcessing ? (
                    <>
                      <Loader2 className="w-6 h-6 mr-2 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    <>
                      <Zap className="w-6 h-6 mr-2" />
                      Upgrade to Sage Evergreen
                    </>
                  )}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default SubscriptionManagementPage;