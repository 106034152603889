import React, { useEffect, useState } from 'react';
import { useAuth } from "./AuthContext";
import { Link } from 'react-router-dom';
import { MessageSquare, ChevronRight, Loader2, MessageCircle, Zap, CreditCard, AlertCircle } from 'lucide-react';
import { Button } from "./components/ui/Button";
import { Card, CardHeader, CardContent } from "./components/ui/Card";
import { Alert } from "./components/ui/Alert";

const ConversationListItem = ({ conversation, darkMode }) => {
  const formattedDate = new Date(conversation.date).toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  });

  return (
    <div className={`flex items-center justify-between p-4 border-b ${darkMode ? 'border-warm_gray' : 'border-cream'} last:border-b-0`}>
      <div className="flex items-center space-x-4">
        <div>
          <p className={`font-medium ${darkMode ? 'text-cream' : 'text-warm_gray'}`}>{conversation.headline}</p>
          <p className={`text-sm ${darkMode ? 'text-white' : 'text-warm_gray'}`}>{formattedDate}</p>
        </div>
      </div>
      <Link to={`/chat/${conversation.id}`}>
        <Button variant="ghost" size="sm">
          View <ChevronRight className="w-4 h-4 ml-1" />
        </Button>
      </Link>
    </div>
  );
};

const DashboardPage = ({ darkMode }) => {
  const { user } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [sageMessage, setSageMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        const token = await user.getIdToken();
        const [conversationsResponse, letterResponse, subscriptionResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/chat/sessions`, { headers: { 'Authorization': `Bearer ${token}` } }),
          fetch(`${process.env.REACT_APP_API_URL}/chat/letter`, { headers: { 'Authorization': `Bearer ${token}` } }),
          fetch(`${process.env.REACT_APP_API_URL}/payment/subscription-status`, { headers: { 'Authorization': `Bearer ${token}` } })
        ]);

        if (!conversationsResponse.ok || !letterResponse.ok || !subscriptionResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const [conversationsData, letterData, subscriptionData] = await Promise.all([
          conversationsResponse.json(),
          letterResponse.json(),
          subscriptionResponse.json()
        ]);

        setConversations(conversationsData);
        setSageMessage(letterData.message);
        setSubscriptionStatus(subscriptionData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    document.body.style.backgroundColor = darkMode ? '#18181b' : '#f0fdfa';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [darkMode]);

  const handleUpgrade = async () => {
    setIsUpgrading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { url } = await response.json();
      window.location = url;
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError('Failed to initiate upgrade process. Please try again.');
    } finally {
      setIsUpgrading(false);
    }
  };

  const isGracePeriod = !subscriptionStatus.isSubscribed && subscriptionStatus.cancelAtPeriodEnd && new Date(subscriptionStatus.cancelAtPeriodEnd * 1000) > new Date();

  if (isLoading) {
    return (
      <div className={`flex justify-center items-center h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`flex justify-center items-center h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen relative ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 flex items-center">
          Dashboard
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Card className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'}`}>
            <CardHeader>
              <h2 className="text-xl font-semibold flex items-center">
                <MessageSquare className="w-6 h-6 mr-2 text-terracotta dark:text-amber" />
                Recent Conversations
              </h2>
            </CardHeader>
            <CardContent>
              <div className="h-[400px] overflow-y-auto">
                {conversations.map(conversation => (
                  <ConversationListItem key={conversation.id} conversation={conversation} darkMode={darkMode} />
                ))}
              </div>
            </CardContent>
          </Card>
          <div className="space-y-8">
            <Card className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} p-6`}>
              <CardHeader>
                <h2 className="text-xl font-semibold flex items-center">
                  <MessageCircle className="w-6 h-6 mr-2 text-terracotta dark:text-amber" />
                  Message from Sage
                </h2>
              </CardHeader>
              <CardContent>
                <blockquote className={`text-2xl italic font-light text-left ${darkMode ? 'text-white' : 'text-warm_gray'}`}>
                  "{sageMessage}"
                </blockquote>
              </CardContent>
            </Card>
            <Card className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} p-6`}>
              <CardHeader>
                <h2 className="text-xl font-semibold flex items-center">
                  <Zap className="w-6 h-6 mr-2 text-terracotta dark:text-amber" />
                  Quick Actions
                </h2>
              </CardHeader>
              <CardContent>
                <Link to="/chat">
                  <Button className={`w-full max-w-2xl mx-auto bg-terracotta hover:bg-amber text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center`}>
                    Start New Conversation
                  </Button>
                </Link>
              </CardContent>
            </Card>
            {isGracePeriod && (
              <Card className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} p-6`}>
                <CardHeader>
                  <h2 className="text-xl font-semibold flex items-center">
                    <AlertCircle className="w-6 h-6 mr-2 text-amber" />
                    Subscription Ending Soon
                  </h2>
                </CardHeader>
                <CardContent>
                  <Alert variant="warning" className={`${darkMode ? 'bg-terracotta border-amber text-cream' : ''} mb-4`}>
                    <AlertCircle className="h-4 w-4 mr-2" />
                    <span>Your subscription will end on {new Date(subscriptionStatus.cancelAtPeriodEnd * 1000).toLocaleDateString()}. Renew now to keep your premium access!</span>
                  </Alert>
                  <Link to="/subscription">
                    <Button className={`w-full max-w-2xl mx-auto bg-terracotta hover:bg-amber text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center ${
                      darkMode ? 'hover:bg-amber' : 'hover:bg-terracotta'
                    }`}>
                      Manage Subscription
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            )}
            {!subscriptionStatus.isSubscribed && !isGracePeriod && (
              <Card className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} p-6`}>
                <CardHeader>
                  <h2 className="text-xl font-semibold flex items-center">
                    <CreditCard className="w-6 h-6 mr-2 text-terracotta dark:text-amber" />
                    Upgrade Your Account
                  </h2>
                </CardHeader>
                <CardContent>
                  <p className={`mb-4 ${darkMode ? 'text-white' : 'text-warm_gray'}`}>
                    Unlock premium features and enjoy an enhanced experience!
                  </p>
                  <Button 
                    onClick={handleUpgrade}
                    disabled={isUpgrading}
                    className={`w-full max-w-2xl mx-auto bg-terracotta hover:bg-amber text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center ${
                      darkMode ? 'hover:bg-amber' : 'hover:bg-terracotta'
                    }`}
                  >
                    {isUpgrading ? (
                      <>
                        <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      <>
                        Upgrade Now
                      </>
                    )}
                  </Button>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardPage;