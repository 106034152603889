import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TreePine, Menu } from 'lucide-react';
import { Button } from "./ui/Button";
import DropdownMenu from "./DropdownMenu";
import { useAuth } from '../AuthContext';

const Toolbar = ({ darkMode, toggleDarkMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useAuth();
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className={`flex justify-between items-center p-4 ${darkMode ? 'bg-warm_gray' : 'bg-cream'} shadow-sm transition-colors duration-300 ease-in-out`}>
      <Link to="/" className="flex items-center">
        <TreePine className="h-8 w-8 text-green mr-2" />
        <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-terracotta to-amber">
          Insight Path AI
        </span>
      </Link>
      
      <div className="flex items-center">
        <nav className="hidden md:flex space-x-4 mr-4">
          {!user && (
            <Link to="/" className="text-warm_gray dark:text-white hover:text-warm_gray dark:hover:text-cream">
              Home
            </Link>
          )}
          {user && (
            <Link to="/dashboard" className="text-warm_gray dark:text-white hover:text-warm_gray dark:hover:text-cream">
              Dashboard
            </Link>
          )}
          <Link to="/blog" className="text-warm_gray dark:text-white hover:text-warm_gray dark:hover:text-cream">
            Blog
          </Link>
        </nav>

        {!user && (
          <Link to="/login" className="mr-4">
            <Button className="hidden md:inline-flex bg-terracotta hover:bg-amber text-cream font-bold py-2 px-4 rounded-full text-sm transition duration-300 ease-in-out">
              Log In / Sign Up
            </Button>
          </Link>
        )}

        <Button
          variant="ghost"
          size="icon"
          onClick={toggleMenu}
          className="text-warm_gray dark:text-white hover:bg-cream dark:hover:bg-warm_gray hover:text-warm_gray dark:hover:text-cream"
        >
          <Menu className="h-6 w-6" />
        </Button>
      </div>

      <DropdownMenu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        isLoggedIn={!!user}
      />
    </header>
  );
};

export default Toolbar;