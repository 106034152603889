import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Button } from './components/ui/Button';
import BreathingCircle from './components/BreathingCircle';
import { Play, AlertCircle, Loader2, TreePine } from 'lucide-react';
import { Alert } from './components/ui/Alert';

const WelcomeMessage = ({ timeOfDay }) => (
  <div className="text-center mb-12">
    <div className="flex items-center justify-center mb-6">
      <TreePine className="h-8 w-8 text-green" />
    </div>
    <h1 className="text-3xl font-bold mb-4 text-warm_gray dark:text-cream">
      {timeOfDay === 'morning' && 'Good morning'}
      {timeOfDay === 'afternoon' && 'Good afternoon'}
      {timeOfDay === 'evening' && 'Good evening'}
    </h1>
    <p className="text-lg text-warm_gray dark:text-cream">Take a moment to find a quiet space where you can be present</p>
  </div>
);

const StartSessionButton = ({ onClick, isLoading, darkMode }) => {
  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      className={`w-full bg-terracotta hover:bg-amber text-cream rounded-full p-4 transition-all duration-300 ease-in-out flex items-center justify-center ${
        darkMode ? 'hover:bg-amber shadow-lg' : 'hover:bg-terracotta'
      }`}
    >
      {isLoading ? (
        <>
          <Loader2 className="w-5 h-5 mr-2 animate-spin" />
          <span className="text-lg font-semibold">Opening your space...</span>
        </>
      ) : (
        <>
          <Play className="h-5 w-5 mr-2" />
          <span className="text-lg font-semibold">Begin Your Journey with Sage</span>
        </>
      )}
    </Button>
  );
};

const SessionInitiationPage = ({ darkMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [canStartNewSession, setCanStartNewSession] = useState(null);
  const [isChecking, setIsChecking] = useState(true);
  const [timeOfDay, setTimeOfDay] = useState('morning');
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) setTimeOfDay('morning');
    else if (hour >= 12 && hour < 17) setTimeOfDay('afternoon');
    else setTimeOfDay('evening');
  }, []);

  const checkCanStartNewSession = useCallback(async () => {
    if (!user) return;
    try {
      const token = await user.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/can-start-new-session`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) throw new Error('Failed to check session availability');
      const data = await response.json();
      setCanStartNewSession(data.canStartNewSession);
    } catch (err) {
      console.error('Error checking session availability:', err);
      setCanStartNewSession(false);
    } finally {
      setIsChecking(false);
    }
  }, [user]);

  useEffect(() => {
    checkCanStartNewSession();
  }, [checkCanStartNewSession]);

  const handleStartSession = async () => {
    if (!user) {
      alert("Please log in to start a session.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`
        },
        body: JSON.stringify({
          userId: user.uid,
        }),
      });

      if (!response.ok) throw new Error('Failed to start session');
      const data = await response.json();
      navigate(`/chat/${data.sessionId}`, { state: { initialMessage: data.aiResponse } });
    } catch (error) {
      console.error('Error starting session:', error);
      alert("Failed to start session. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/subscription');
  };

  if (isChecking) {
    return (
      <div className={`flex items-center justify-center h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className={`min-h-screen relative ${darkMode ? 'bg-warm_gray' : 'bg-cream'}`}>
      <div className="flex flex-col items-center justify-center p-4">
        <div className="flex flex-col items-center w-full max-w-2xl relative z-10">
          <WelcomeMessage timeOfDay={timeOfDay} />
          <div className="mb-16">
            <BreathingCircle inTime={4} topTime={2} outTime={6} bottomTime={2} darkMode={darkMode} />
          </div>
          {canStartNewSession ? (
            <div className="w-full space-y-4">
              <p className="text-center text-lg mb-8 text-warm_gray dark:text-cream">When you feel centered, begin your session</p>
              <StartSessionButton onClick={handleStartSession} isLoading={isLoading} darkMode={darkMode} />
            </div>
          ) : (
            <div className="w-full space-y-4 bg-cream/90 dark:bg-warm_gray/90 rounded-lg p-6 backdrop-blur-sm">
              <Alert variant="warning" className="flex items-center">
                <AlertCircle className="h-4 w-4 mr-2" />
                <span className="text-warm_gray dark:text-cream">You may only start one session with Sage per week. Subscribe to Sage Evergreen to gain unlimited access.</span>
              </Alert>
              <Button
                onClick={handleSubscribe}
                className={`w-full bg-terracotta hover:bg-amber text-cream rounded-full p-4 transition-all duration-300 ease-in-out flex items-center justify-center ${
                  darkMode ? 'hover:bg-amber' : 'hover:bg-terracotta'
                }`}
              >
                <span className="font-semibold">Manage Subscription</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionInitiationPage;