import React from 'react';

const BackgroundPattern = () => {
  return (
    <div className="fixed inset-0 z-0 pointer-events-none overflow-hidden w-screen h-screen">
      <svg 
        className="absolute w-full h-full"
        xmlns="http://www.w3.org/2000/svg" 
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 0 2000 2000"
      >
        <defs>
          {/* Leaf Pattern */}
          <pattern 
            id="leaf-pattern" 
            x="0" 
            y="0" 
            width="200" 
            height="200" 
            patternUnits="userSpaceOnUse"
            patternTransform="scale(1.5) rotate(15)"
          >
            {/* Flowing lines */}
            <path 
              d="M0,100 C50,80 150,120 200,100" 
              fill="none" 
              stroke="#739669"
              strokeWidth="2"
              opacity="0.15"
            />
            <path 
              d="M0,150 C50,130 150,170 200,150" 
              fill="none" 
              stroke="#E07A5F"
              strokeWidth="2"
              opacity="0.12"
            />
            
            {/* Bohemian circles */}
            <circle cx="40" cy="40" r="3" fill="#F4A261" opacity="0.15" />
            <circle cx="160" cy="160" r="2" fill="#739669" opacity="0.15" />
            <circle cx="180" cy="40" r="2.5" fill="#E07A5F" opacity="0.12" />
            
            {/* Organic shapes */}
            <path 
              d="M120,80 Q140,70 130,50 Q120,70 120,80 Z" 
              fill="#739669"
              opacity="0.12"
            />
            <path 
              d="M60,140 Q80,130 70,110 Q60,130 60,140 Z" 
              fill="#E07A5F"
              opacity="0.1"
            />
            
            {/* Delicate ferns */}
            <path 
              d="M100,50 Q110,45 105,35 Q100,45 100,50 M95,45 Q105,40 100,30 Q95,40 95,45"
              fill="#739669"
              opacity="0.12"
            />
          </pattern>

          {/* Flowing Pattern */}
          <pattern 
            id="flow-pattern" 
            x="0" 
            y="0" 
            width="300" 
            height="300" 
            patternUnits="userSpaceOnUse"
            patternTransform="scale(2) rotate(-15)"
          >
            <path 
              d="M0,150 Q75,100 150,150 T300,150" 
              fill="none" 
              stroke="#739669"
              strokeWidth="1"
              opacity="0.1"
            />
            <path 
              d="M0,100 Q75,50 150,100 T300,100" 
              fill="none" 
              stroke="#E07A5F"
              strokeWidth="1"
              opacity="0.08"
            />
          </pattern>
        </defs>

        {/* Background layers */}
        <rect 
          x="0" 
          y="0" 
          width="100%" 
          height="100%" 
          fill="url(#flow-pattern)"
        />
        <rect 
          x="0" 
          y="0" 
          width="100%" 
          height="100%" 
          fill="url(#leaf-pattern)"
        />

        {/* Large decorative waves */}
        <g opacity="0.1">
          <path 
            d="M-100,400 Q800,200 1700,400 T2100,600" 
            fill="none" 
            stroke="#F4A261"
            strokeWidth="3"
          />
          <path 
            d="M-100,1000 Q800,800 1700,1000 T2100,1200" 
            fill="none" 
            stroke="#739669"
            strokeWidth="3"
          />
          <path 
            d="M-100,1600 Q800,1400 1700,1600 T2100,1800" 
            fill="none" 
            stroke="#E07A5F"
            strokeWidth="3"
          />
        </g>

        {/* Scattered elements */}
        <g opacity="0.08">
          <path d="M500,200 Q600,150 550,100 Q500,150 500,200 Z" fill="#739669" />
          <path d="M1500,1800 Q1600,1750 1550,1700 Q1500,1750 1500,1800 Z" fill="#E07A5F" />
          <path d="M1800,400 Q1900,350 1850,300 Q1800,350 1800,400 Z" fill="#F4A261" />
        </g>
      </svg>
    </div>
  );
};

export default BackgroundPattern;