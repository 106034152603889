import React from 'react';

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={`w-full px-3 py-2 text-sm leading-tight text-warm_gray dark:text-white rounded shadow appearance-none focus:outline-none focus:shadow-outline ${className}`}
      ref={ref}
      {...props}
    />
  );
});

Input.displayName = 'Input';

export { Input };