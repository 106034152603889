import React from 'react';
import './BlogPost.css';
import { useParams, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

const BlogPostPage = ({ posts, darkMode }) => {
  const { id } = useParams();
  const post = posts.find((p) => p.id === id);

  if (!post) {
    return (
      <div className={`min-h-screen flex items-center justify-center ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <div className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} p-6`}>
          <p>Post not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <main className="container mx-auto px-4 py-8">
        <Link to="/blog" className={`${darkMode ? 'text-amber hover:text-amber' : 'text-terracotta hover:text-terracotta'} mb-4 inline-flex items-center`}>
          <ChevronLeft className="w-4 h-4 mr-1" />
          Back to Blog
        </Link>
        <div className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} my-4 max-w-3xl mx-auto`}>
          <div className="flex flex-col space-y-4">
            <p className={`text-center text-sm ${darkMode ? 'text-amber' : 'text-warm_gray'}`}>{post.date}</p>
            <h1 className="text-center text-4xl font-bold">{post.title}</h1>
            <div className="h-4" />
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-64 object-cover rounded-lg"
            />
          </div>
          <div
            className={`blog-content mx-2 mt-8 ${darkMode ? 'text-cream' : 'text-warm_gray'}`}
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </main>
    </div>
  );
};

export default BlogPostPage;