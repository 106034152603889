import React, { useState, useEffect, useRef } from 'react';

const BreathingCircle = ({
  inTime = 4,
  topTime = 2,
  outTime = 6,
  bottomTime = 2,
  darkMode = false
}) => {
  const [scale, setScale] = useState(1);
  const [phase, setPhase] = useState('inhale');
  const [progress, setProgress] = useState(0);
  const animationRef = useRef(null);
  const lastTimeRef = useRef(0);
  const elapsedTimeRef = useRef(0);
  const cycleDuration = inTime + topTime + outTime + bottomTime;

  useEffect(() => {
    const animate = (currentTime) => {
      if (lastTimeRef.current !== 0) {
        const deltaTime = (currentTime - lastTimeRef.current) / 1000;
        elapsedTimeRef.current += deltaTime;
        const cycleTime = elapsedTimeRef.current % cycleDuration;

        if (cycleTime < inTime) {
          // Inhale phase
          setPhase('inhale');
          const progress = cycleTime / inTime;
          setScale(1 + (0.5 * progress));
          setProgress(progress);
        } else if (cycleTime < inTime + topTime) {
          // Hold at top
          setPhase('holdTop');
          setScale(1.5);
          setProgress(1);
        } else if (cycleTime < inTime + topTime + outTime) {
          // Exhale phase
          setPhase('exhale');
          const exhaleTime = cycleTime - inTime - topTime;
          const progress = exhaleTime / outTime;
          setScale(1.5 - (0.5 * progress));
          setProgress(1 - progress);
        } else {
          // Hold at bottom
          setPhase('holdBottom');
          setScale(1);
          setProgress(0);
        }
      }
      lastTimeRef.current = currentTime;
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [inTime, topTime, outTime, bottomTime, cycleDuration]);

  const getColor = () => {
    const amber = [244, 162, 97];
    const terracotta = [224, 122, 95];
    const r = Math.round(amber[0] + progress * (terracotta[0] - amber[0]));
    const g = Math.round(amber[1] + progress * (terracotta[1] - amber[1]));
    const b = Math.round(amber[2] + progress * (terracotta[2] - amber[2]));
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        <div
          className="w-24 h-24 sm:w-32 sm:h-32 rounded-full flex items-center justify-center"
          style={{
            transform: `scale(${scale})`,
            backgroundColor: getColor(),
          }}
        >
          <div style={{ transform: `scale(${1/scale})` }} className="text-xs sm:text-sm font-semibold text-white">
            {phase === 'inhale' ? 'Breathe In' :
             phase === 'holdTop' ? 'Hold' :
             phase === 'exhale' ? 'Breathe Out' :
             'Rest'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreathingCircle;