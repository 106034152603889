import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/Dialog";
import { Button } from "./ui/Button";

const EndSessionModal = ({ showModal, onClose, onConfirm, darkMode }) => (
  <Dialog open={showModal} onOpenChange={onClose}>
    <DialogContent className={`${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <DialogHeader>
        <DialogTitle className={`${darkMode ? 'text-cream' : 'text-warm_gray'}`}>End Session</DialogTitle>
        <DialogDescription className={`${darkMode ? 'text-white' : 'text-warm_gray'}`}>
          Are you sure you want to end this session? All messages will be cleared and a summary of the session will be generated.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button 
          variant="outline" 
          onClick={onClose}
          className={`${darkMode ? 'bg-warm_gray text-cream hover:bg-warm_gray' : 'bg-cream text-warm_gray hover:bg-cream'}`}
        >
          Cancel
        </Button>
        <Button 
          variant="destructive" 
          onClick={onConfirm}
          className="bg-terracotta text-white hover:bg-amber"
        >
          End Session
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export default EndSessionModal;