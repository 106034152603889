import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Loader2 } from 'lucide-react';
import { Button } from "./components/ui/Button";
import { Alert } from "./components/ui/Alert";

const CustomInput = ({ label, name, type, value, onChange, placeholder, required }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-sm font-medium text-warm_gray dark:text-white mb-1">
      {label}
    </label>
    <input
      type={type}
      name={name}
      id={name}
      value={value || ''}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className="w-full px-3 py-2 placeholder-warm_gray border border-warm_gray rounded-md focus:outline-none focus:ring focus:ring-amber focus:border-terracotta dark:bg-warm_gray dark:text-cream dark:placeholder-cream dark:border-cream dark:focus:ring-terracotta dark:focus:border-terracotta"
    />
  </div>
);

const CustomTextarea = ({ label, name, value, onChange, placeholder, required }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-sm font-medium text-warm_gray dark:text-white mb-1">
      {label}
    </label>
    <textarea
      name={name}
      id={name}
      value={value || ''}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      rows="3"
      className="w-full px-3 py-2 placeholder-warm_gray border border-warm_gray rounded-md focus:outline-none focus:ring focus:ring-amber focus:border-terracotta dark:bg-warm_gray dark:text-cream dark:placeholder-cream dark:border-cream dark:focus:ring-terracotta dark:focus:border-terracotta"
    />
  </div>
);

const ProfilePage = ({ darkMode }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(true);
  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
    marital_or_relationship_status: '',
    employment_status_or_occupation: '',
    education_level: '',
    cultural_or_ethnic_background: '',
    religious_or_spiritual_beliefs: '',
    living_situation: '',
    general_health_status: ''
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${await user.getIdToken()}`
            },
          });
          if (response.ok) {
            const data = await response.json();
            setProfileData(data || {});
            if (Object.keys(data).length > 0) {
              setIsNewProfile(false);
            }
          } else if (response.status === 404) {
            setIsNewProfile(true);
          } else {
            throw new Error('Failed to fetch profile');
          }
        } catch (err) {
          if (err.response && err.response.status !== 404) {
            setError('Failed to fetch profile. Please try again.');
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfile();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`
        },
        body: JSON.stringify({ 
          profileData: profileData,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update profile');
      }
      navigate('/chat');
    } catch (err) {
      setError('Failed to update profile. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className={`flex justify-center items-center h-screen ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className={`min-h-screen py-12 px-4 sm:px-6 lg:px-8 ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <div className="max-w-3xl mx-auto">
        <div className={`bg-cream dark:bg-warm_gray shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 ${darkMode ? 'text-cream' : 'text-warm_gray'}`}>
          <h2 className="text-3xl font-bold text-center mb-6">
            {isNewProfile ? 'Create Your Profile' : 'Update Your Profile'}
          </h2>
          <p className="text-center text-warm_gray dark:text-white mb-8">
            Help us personalize your experience with Sage
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <CustomInput
                label="First Name"
                name="first_name"
                type="text"
                value={profileData.first_name}
                onChange={handleChange}
                placeholder="Enter your first name"
                required
              />
              <CustomInput
                label="Last Name"
                name="last_name"
                type="text"
                value={profileData.last_name}
                onChange={handleChange}
                placeholder="Enter your last name"
                required
              />
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <CustomInput
                label="Date of Birth"
                name="date_of_birth"
                type="date"
                value={profileData.date_of_birth}
                onChange={handleChange}
                required
              />
              <CustomInput
                label="Gender"
                name="gender"
                type="text"
                value={profileData.gender}
                onChange={handleChange}
                placeholder="Enter your gender"
                required
              />
            </div>
            <CustomInput
              label="Marital or Relationship Status"
              name="marital_or_relationship_status"
              type="text"
              value={profileData.marital_or_relationship_status}
              onChange={handleChange}
              placeholder="Enter your marital or relationship status"
              required
            />
            <CustomInput
              label="Employment Status or Occupation"
              name="employment_status_or_occupation"
              type="text"
              value={profileData.employment_status_or_occupation}
              onChange={handleChange}
              placeholder="Enter your employment status or occupation"
              required
            />
            <CustomInput
              label="Education Level"
              name="education_level"
              type="text"
              value={profileData.education_level}
              onChange={handleChange}
              placeholder="Enter your education level"
              required
            />
            <CustomTextarea
              label="Cultural or Ethnic Background"
              name="cultural_or_ethnic_background"
              value={profileData.cultural_or_ethnic_background}
              onChange={handleChange}
              placeholder="Describe your cultural or ethnic background"
              required
            />
            <CustomTextarea
              label="Religious or Spiritual Beliefs"
              name="religious_or_spiritual_beliefs"
              value={profileData.religious_or_spiritual_beliefs}
              onChange={handleChange}
              placeholder="Describe your religious or spiritual beliefs"
              required
            />
            <CustomInput
              label="Living Situation (e.g. alone, with family, etc.)"
              name="living_situation"
              type="text"
              value={profileData.living_situation}
              onChange={handleChange}
              placeholder="Describe your living situation"
              required
            />
            <CustomTextarea
              label="General Health Status"
              name="general_health_status"
              value={profileData.general_health_status}
              onChange={handleChange}
              placeholder="Describe your general health status"
              required
            />

            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}

            <div className="flex items-center justify-end">
              <Button
                type="submit"
                className="px-6 py-3 bg-terracotta text-cream rounded-md hover:bg-amber focus:outline-none focus:ring-2 focus:ring-amber focus:ring-offset-2 transition-colors duration-300"
              >
                {isNewProfile ? 'Create Profile' : 'Update Profile'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;