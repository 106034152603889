import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MessageCircle, Brain, Heart, TreePine } from 'lucide-react';
import { Button } from "./components/ui/Button";
import BackgroundPattern from "./components/BackgroundPattern";

const TypeWriter = () => {
  const words = useMemo(() => ["coach", "guide", "companion", "confidant", "listener", "mentor"], []);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typingSpeed = isDeleting ? 50 : 100;
    const deletingDelay = 1500;
    const word = words[currentWordIndex];

    if (!isDeleting && currentText === word) {
      const timeout = setTimeout(() => setIsDeleting(true), deletingDelay);
      return () => clearTimeout(timeout);
    }

    if (isDeleting && currentText === "") {
      setIsDeleting(false);
      setCurrentWordIndex((current) => (current + 1) % words.length);
      return;
    }

    const handleTyping = () => {
      setCurrentText(current => {
        if (isDeleting) {
          return current.slice(0, -1);
        }
        return word.slice(0, current.length + 1);
      });
    };

    const timeout = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(timeout);
  }, [currentText, isDeleting, currentWordIndex, words]);

  return (
    <span className="text-transparent bg-clip-text bg-gradient-to-r from-terracotta to-amber">
      {currentText}
      <span className="animate-pulse">|</span>
    </span>
  );
};

const Feature = ({ icon: Icon, title, description }) => (
  <div className="group flex flex-col items-center p-8 rounded-lg bg-cream/100 dark:bg-warm_gray/100 transition-all backdrop-blur-sm">
    <div className="mb-4 p-3 rounded-full bg-green/10 group-hover:bg-green/20 transition-colors">
      <Icon className="h-8 w-8 text-green" />
    </div>
    <h3 className="text-xl font-semibold mb-3 text-warm_gray dark:text-cream">{title}</h3>
    <p className="text-center text-warm_gray/80 dark:text-cream/80">{description}</p>
  </div>
);

const features = [
  {
    icon: Brain,
    title: "AI-Powered Reflection",
    description: "Sage uses advanced natural language processing to help you explore your thoughts and feelings with depth and clarity."
  },
  {
    icon: Heart,
    title: "Emotional Intelligence",
    description: "Develop greater self-awareness and understanding through guided conversations and personalized insights."
  },
  {
    icon: MessageCircle,
    title: "24/7 Companionship",
    description: "Get supportive, judgment-free conversations whenever you need them, helping you process and grow."
  }
];

const HomePage = ({ darkMode }) => {
  return (
    <div className={`min-h-screen relative ${darkMode ? 'bg-warm_gray' : 'bg-cream'}`}>
      <BackgroundPattern />
      <main className="max-w-5xl mx-auto px-4 py-16">
        {/* Hero Section */}
        <div className="text-center mb-24 rounded-lg bg-cream dark:bg-warm_gray p-12 relative overflow-hidden">
          <div className="absolute inset-0 bg-cream dark:bg-warm_gray"></div>
          <div className="relative z-10">
            <div className="flex items-center justify-center mb-8">
              <TreePine className="h-12 w-12 text-green" />
            </div>
            <h1 className="text-5xl font-bold mb-6 text-warm_gray dark:text-cream">
              Your AI <TypeWriter />
              <span className="block text-warm_gray dark:text-cream mt-2">
                for Personal Growth
              </span>
            </h1>
            <p className="text-xl mb-12 max-w-2xl mx-auto text-warm_gray/80 dark:text-cream/80">
              Meet Sage, an empathetic AI designed to help you explore your inner world, 
              process your experiences, and grow with confidence.
            </p>
            <div className="space-y-4">
              <Link to="/chat">
                <Button className="bg-terracotta hover:bg-amber text-cream font-semibold py-4 px-8 rounded-full text-lg transition-colors">
                  Start a Conversation
                </Button>
              </Link>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-24">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>

        {/* Final CTA */}
        <div className="text-center bg-cream dark:bg-warm_gray rounded-2xl p-12 relative overflow-hidden">
          <div className="relative">
            <h2 className="text-3xl font-bold mb-4 text-warm_gray dark:text-cream">
              Begin Your Journey
            </h2>
            <p className="text-xl mb-8 text-warm_gray/80 dark:text-cream/80 max-w-2xl mx-auto">
              Experience the power of AI-guided self-reflection. Sage is here to listen, 
              understand, and help you grow.
            </p>
            <Link to="/chat">
              <Button className="bg-terracotta hover:bg-amber text-cream font-semibold py-4 px-8 rounded-full text-lg transition-colors">
                Chat with Sage
              </Button>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;