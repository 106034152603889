import React from 'react';
import { Link } from 'react-router-dom';
import { Check, MessageCircle, Zap, TreePine, Heart, Stars, Sparkles } from 'lucide-react';
import { Button } from "./components/ui/Button";
import BackgroundPattern from "./components/BackgroundPattern";

const PricingTier = ({ name, price, conversationFrequency, features, isRecommended, darkMode, icon: Icon }) => (
  <div className={`relative flex flex-col p-8 mx-auto max-w-lg text-center 
    ${isRecommended ? 'border-2 border-terracotta transform hover:-translate-y-2' : 'border border-warm_gray hover:-translate-y-1'} 
    rounded-2xl shadow-lg transition-all duration-300 backdrop-blur-sm
    ${darkMode ? 'bg-warm_gray/90 text-cream' : 'bg-cream/90 text-warm_gray'} xl:p-10`}>
    {isRecommended && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-terracotta text-cream px-4 py-1 rounded-full text-sm font-medium">
        Recommended
      </div>
    )}
    <div className="flex items-center justify-center mb-6">
      <div className={`p-3 rounded-full ${isRecommended ? 'bg-terracotta/20' : 'bg-green/10'}`}>
        <Icon className={`h-8 w-8 ${isRecommended ? 'text-terracotta' : 'text-green'}`} />
      </div>
    </div>
    <h3 className="mb-4 text-2xl font-bold">{name}</h3>
    <p className="font-medium text-warm_gray/80 dark:text-amber sm:text-lg mb-4 flex items-center justify-center">
      <MessageCircle className="mr-2" size={20} />
      {conversationFrequency}
    </p>
    <div className="flex justify-center items-baseline my-8">
      {price === 0 ? (
        <span className="text-5xl font-extrabold text-warm_gray dark:text-cream">
          Free
        </span>
      ) : (
        <div className="flex items-baseline">
          <span className="text-5xl font-extrabold text-warm_gray dark:text-cream">
            ${price}
          </span>
          <span className="ml-2 text-warm_gray/80 dark:text-amber">/month</span>
        </div>
      )}
    </div>
    <ul className="mb-8 space-y-4 text-left">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center space-x-3 group">
          <Check className="flex-shrink-0 w-5 h-5 text-terracotta dark:text-amber transition-transform duration-300 group-hover:scale-110" />
          <span className="text-warm_gray/90 dark:text-cream/90">{feature}</span>
        </li>
      ))}
    </ul>
    <Link to="/login" className="mt-auto">
      <Button className={`w-full font-bold py-4 px-8 rounded-full text-lg transition-all duration-300 
        ${price === 0 
          ? 'bg-cream dark:bg-warm_gray text-terracotta dark:text-amber hover:bg-terracotta/10 dark:hover:bg-amber/10 border-2 border-terracotta dark:border-amber' 
          : 'bg-terracotta hover:bg-amber text-cream shadow-lg hover:shadow-xl'}`}>
        {price === 0 ? "Begin Your Journey" : "Embrace Growth"}
      </Button>
    </Link>
  </div>
);

const GrowthMilestone = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-6 text-center group">
    <div className="mb-4 p-3 rounded-full bg-terracotta/10 group-hover:bg-terracotta/20 transition-all duration-300">
      <Icon className="h-6 w-6 text-terracotta" />
    </div>
    <h4 className="text-lg font-semibold mb-2">{title}</h4>
    <p className="text-warm_gray/80 dark:text-cream/80">{description}</p>
  </div>
);

const PricingPage = ({ darkMode }) => {
  const sharedFeatures = [
    "Private, judgment-free conversations with Sage",
    "24/7 availability",
    "Progress tracking",
  ];

  const milestones = [
    {
      icon: Heart,
      title: "Self-Discovery",
      description: "Begin your journey of inner exploration with gentle guidance and support."
    },
    {
      icon: Zap,
      title: "Transformative Growth",
      description: "Experience breakthrough moments and lasting personal evolution."
    },
    {
      icon: Stars,
      title: "Lasting Change",
      description: "Build sustainable habits and embrace your authentic self."
    }
  ];

  return (
    <div className={`min-h-screen relative ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <BackgroundPattern />
      <main className="relative z-10 py-16 px-4 mx-auto w-full max-w-screen-xl">
        <div className="mx-auto max-w-3xl text-center mb-16 bg-cream/80 dark:bg-warm_gray/80 rounded-2xl p-12 backdrop-blur-sm">
          <div className="flex items-center justify-center mb-6">
            <TreePine className="h-12 w-12 text-green" />
          </div>
          <h2 className="mb-6 text-4xl tracking-tight font-bold">
            Choose Your Path to Growth
          </h2>
          <p className="text-xl font-light text-warm_gray/80 dark:text-cream/80">
            Every journey begins with a single step. Select the path that 
            resonates with your aspirations for personal growth and emotional well-being.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-8 mb-16">
          <PricingTier
            name="Sage Sapling"
            price={0}
            conversationFrequency="Weekly nurturing conversations"
            features={[
              ...sharedFeatures,
              "One conversation per week"
            ]}
            darkMode={darkMode}
            icon={TreePine}
          />
          <PricingTier
            name="Sage Evergreen"
            price={15}
            conversationFrequency="Unlimited nurturing conversations"
            features={[
              ...sharedFeatures,
              "Unlimited conversations with Sage"
            ]}
            isRecommended={true}
            darkMode={darkMode}
            icon={Sparkles}
          />
        </div>

        <div className="max-w-3xl mx-auto text-center mb-16 bg-cream/80 dark:bg-warm_gray/80 rounded-2xl p-12 backdrop-blur-sm">
          <h3 className="text-2xl font-bold mb-8">Your Growth Journey with Sage</h3>
          <div className="grid md:grid-cols-3 gap-8">
            {milestones.map((milestone, index) => (
              <GrowthMilestone key={index} {...milestone} />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default PricingPage;