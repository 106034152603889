import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CreditCard, LogOut, LogIn, Moon, Sun, LayoutDashboard, User, FileText } from 'lucide-react';
import { useAuth } from '../AuthContext';
import { Switch } from "./ui/Switch";

const DropdownMenu = ({ isOpen, onClose, darkMode, toggleDarkMode, isLoggedIn }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleLogout = async () => {
    try {
      await logout();
      onClose();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const loggedInItems = [
    { icon: <LayoutDashboard size={18} />, text: 'Dashboard', path: '/dashboard' },
    { icon: <FileText size={18} />, text: 'Blog', path: '/blog' },
    { icon: <User size={18} />, text: 'Profile', path: '/profile' },
    { icon: <CreditCard size={18} />, text: 'Manage Subscription', path: '/subscription' },
    { icon: <LogOut size={18} />, text: 'Log Out', action: handleLogout }
  ];

  const guestItems = [
    { icon: <FileText size={18} />, text: 'Blog', path: '/blog' },
    { icon: <CreditCard size={18} />, text: 'Pricing', path: '/pricing' },
    { icon: <LogIn size={18} />, text: 'Log In / Sign Up', path: '/login' },
  ];

  const menuItems = [...(isLoggedIn ? loggedInItems : guestItems)];

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end" onClick={onClose}>
      <div
        className={`mt-16 mr-4 w-64 ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'} rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none`}
        onClick={(e) => e.stopPropagation()}
      >
        {menuItems.map((item, index) => (
          item.path ? (
            <Link key={index} to={item.path}>
              <button
                className={`w-full text-left px-4 py-2 text-sm ${darkMode ? 'hover:bg-terracotta' : 'hover:bg-amber'} flex items-center`}
                onClick={onClose}
              >
                {item.icon}
                <span className="ml-2">{item.text}</span>
              </button>
            </Link>
          ) : (
            <button
              key={index}
              className={`w-full text-left px-4 py-2 text-sm ${darkMode ? 'hover:bg-terracotta hover:text-amber' : 'hover:bg-amber hover:text-terracotta'} flex items-center`}
              onClick={item.action}
            >
              {item.icon}
              <span className="ml-2">{item.text}</span>
            </button>
          )
        ))}
        <hr className={`my-2 ${darkMode ? 'border-terracotta' : 'border-amber'}`} />
        <div className="flex items-center justify-between px-4 py-2 hover:bg-amber dark:hover:bg-terracotta">
          <span className="text-sm">Dark Mode</span>
          <div className="flex items-center space-x-2">
            <Switch
              checked={darkMode}
              onCheckedChange={toggleDarkMode}
              className="bg-amber dark:bg-terracotta"
            />
            <div className="transition-colors duration-300 ease-in-out">
              {darkMode ? (
                <Moon className="h-4 w-4 text-cream" />
              ) : (
                <Sun className="h-4 w-4 text-warm_gray" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;