import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from "./components/ui/Card";
import BackgroundPattern from "./components/BackgroundPattern";

const BlogListPage = ({ posts, darkMode }) => {
  return (
    <div className={`min-h-screen relative ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <BackgroundPattern />
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-center text-4xl font-bold mb-6">Blog Posts</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {posts.map((post) => (
            <Link key={post.id} to={`/blog/${post.id}`}>
              <Card 
                className={`${darkMode ? 'bg-warm_gray' : 'bg-cream'} overflow-hidden transition-transform duration-200 ease-in-out transform`}
              >
                <div className="h-48 overflow-hidden">
                  <img 
                    src={post.image} 
                    alt={post.title} 
                    className="w-full h-full object-cover"
                  />
                </div>
                <CardContent className="p-4">
                  <h3 className="text-2xl font-semibold my-2">
                    {post.title}
                  </h3>
                  <p className={`${darkMode ? 'text-amber' : 'text-warm_gray'} mb-2 text-sm`}>{post.date}</p>
                  <p className={`${darkMode ? 'text-amber' : 'text-warm_gray'}`}>{post.excerpt}</p>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </main>
    </div>
  );
};

export default BlogListPage;