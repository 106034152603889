import React, { useState, useRef, useEffect } from 'react';
import { Send } from 'lucide-react';
import { Button } from "./ui/Button";
import { Textarea } from "./ui/Textarea";

const MAX_CHARACTERS = 10000;

const InputArea = ({ darkMode, onSendMessage, isWaitingForResponse }) => {
  const [inputMessage, setInputMessage] = useState('');
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [inputMessage]);

  const handleInputChange = (e) => {
    const input = e.target.value;
    if (input.length <= MAX_CHARACTERS) {
      setInputMessage(input);
    }
  };

  const handleSend = () => {
    if (inputMessage.trim() && !isWaitingForResponse && inputMessage.length <= MAX_CHARACTERS) {
      onSendMessage(inputMessage);
      setInputMessage('');
    }
  };

  const isOverLimit = inputMessage.length === MAX_CHARACTERS;

  return (
    <div className={`p-4 ${darkMode ? 'bg-warm_gray' : 'bg-cream'}`}>
      <div className="flex flex-col max-w-2xl mx-auto">
        <div className="flex items-end">
          <Textarea
            ref={textareaRef}
            value={inputMessage}
            onChange={handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !isWaitingForResponse && !isOverLimit) {
                e.preventDefault();
                handleSend();
              }
            }}
            placeholder="How can Sage help you today?"
            className={`flex-grow mr-2 ${
              darkMode
                ? 'bg-terracotta/20 text-cream'
                : 'bg-terracotta/10 text-warm_gray'
            } border-none rounded-lg px-4 py-2 min-h-[44px] max-h-32 overflow-y-auto transition-colors duration-300 ease-in-out`}
            style={{ resize: 'none' }}
            disabled={isWaitingForResponse}
          />
          <Button
            onClick={handleSend}
            className={`bg-terracotta hover:bg-amber text-white rounded-full p-3 h-11 w-11 flex-shrink-0 transition-colors duration-300 ease-in-out ${
              isWaitingForResponse || isOverLimit ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isWaitingForResponse || isOverLimit}
          >
            <Send className="h-5 w-5" />
          </Button>
        </div>
        {inputMessage.length > MAX_CHARACTERS * 0.9 && <div className={`text-sm mt-1 text-right ${
          isOverLimit ? 'text-red-500' : darkMode ? 'text-cream' : 'text-warm_gray'
        }`}>
          {inputMessage.length}/{MAX_CHARACTERS}
        </div>}
      </div>
    </div>
  );
};

export default InputArea;