import React from 'react';

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
      className={`flex min-h-[80px] w-full rounded-2xl border border-gray-300 bg-cream dark:bg-warm_gray px-3 py-2 text-sm text-warm_gray dark:text-cream placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-terracotta focus:border-transparent disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      {...props}
    />
  );
});

export { Textarea };