import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from "./components/ui/Button";
import { Input } from "./components/ui/Input";
import { Alert } from "./components/ui/Alert";
import { TreePine } from 'lucide-react';

const LoginPage = ({ darkMode }) => {
  const { googleLogin, sendPasswordlessLink } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = async () => {
    setError('');
    setIsLoading(true);
    try {
      await googleLogin();
      navigate('/profile');
    } catch (err) {
      setError('Failed to log in with Google. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordlessLogin = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsLoading(true);
    try {
      await sendPasswordlessLink(email);
      setMessage('Check your email for a login link!');
    } catch (err) {
      setError('Failed to send login link. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 transition-all ${darkMode ? 'bg-warm_gray text-cream' : 'bg-cream text-warm_gray'}`}>
      <div className="w-full max-w-md">
        <div className="bg-cream dark:bg-warm_gray shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4">
          <div className="flex justify-center items-center mb-6">
            <TreePine className="h-12 w-12 text-green dark:text-amber mr-2" />
            <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-amber to-terracotta">
              Insight Path AI
            </h1>
          </div>
          <p className="text-center text-warm_gray dark:text-white mb-6">Start talking with Sage for yourself</p>
          
          <div className="mb-6">
            <Button
              onClick={handleGoogleLogin}
              disabled={isLoading}
              className="w-full bg-cream hover:bg-cream text-warm_gray font-semibold py-2 px-4 border border-warm_gray rounded-lg shadow inline-flex items-center justify-center transition duration-300 ease-in-out transform"
            >
              <svg className="w-5 h-5 mr-2" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z" fill="#3F83F8"/>
                  <path d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z" fill="#34A853"/>
                  <path d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z" fill="#FBBC04"/>
                  <path d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z" fill="#EA4335"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              {isLoading ? 'Logging in...' : 'Continue with Google'}
            </Button>
          </div>

          <div className="flex items-center justify-between mb-6">
            <hr className="w-full border-t border-warm_gray dark:border-cream" />
            <span className="px-2 text-warm_gray dark:text-white">Or</span>
            <hr className="w-full border-t border-warm_gray dark:border-cream" />
          </div>

          <form onSubmit={handlePasswordlessLogin} className="mb-4">
            <div className="mb-4">
              <label className="block text-warm_gray dark:text-white text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <Input
                type="email"
                placeholder="Enter your email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 placeholder-warm_gray rounded-lg focus:outline-none focus:ring focus:ring-amber bg-cream dark:bg-warm_gray dark:text-white dark:placeholder-cream"
              />
            </div>
            <div className="flex items-center justify-between mb-6">
              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-terracotta hover:bg-amber text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform focus:outline-none focus:shadow-outline"
              >
                {isLoading ? 'Sending...' : 'Send Login Link'}
              </Button>
            </div>
          </form>

          {error && (
            <Alert variant="error" className="mb-4">
              {error}
            </Alert>
          )}
          {message && (
            <Alert variant="success" className="mb-4">
              {message}
            </Alert>
          )}

          <div className="text-center">
            <p className="text-sm text-warm_gray dark:text-white">
              Don't have an account?{' '}
              <Link to="/signup" className="text-terracotta hover:text-amber dark:text-amber dark:hover:text-terracotta">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;